<template>
  <div ref="test_report_wrap" class="test_report_wrap">
    <div class="test_report_main">
      <div class="text_report_left">
        <div>
          <h3>{{ $t("test.report.api") }}</h3>
          <p>{{ api_statistics.api_count }}{{ $t("addNum") }}</p>
        </div>
        <div>
          <h3>{{ $t("test.report.test") }}</h3>
          <p>{{ api_statistics.case_count }}{{ $t("addNum") }}</p>
        </div>
        <div>
          <h3>{{ $t("test.report.cov") }}</h3>
          <div id="pie1" ref="pie1"></div>
        </div>
        <div>
          <h3>{{ $t("test.report.auto") }}</h3>
          <div id="pie2" ref="pie2"></div>
        </div>
      </div>
      <div class="text_report_right">
        <div>
          <h3>{{ $t("test.report.pie") }}</h3>
          <div id="pie3" ref="pie3"></div>
        </div>
      </div>
    </div>
    <div class="test_report_table_wrap">
      <h3>{{ $t("test.report.detail") }}</h3>
      <div class="test_report_table">
        <div>
          <el-table :data="table_data" style="width: 100%">
            <el-table-column
              type="index"
              :label="$t('test.report.num')"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="api_name"
              show-overflow-tooltip
              :label="$t('test.report.name')"
            >
              <template slot-scope="scope">
                <a :href="scope.row.webUrl" target="_blank">{{
                  scope.row.api_name
                }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="api_uri" show-overflow-tooltip label="URL">
            </el-table-column>
            <el-table-column
              prop="api_status"
              width="240"
              :label="$t('test.report.status')"
            >
              <template slot-scope="scope">
                <span class="api_status">{{
                  api_status_map[scope.row.api_status]
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiTestStatistics, getapiDocList } from "@/network/eolink/index.js";
export default {
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      api_status_map: [
        this.$t("nodeDetail.basicInfo.api_status_map.published"),
        this.$t("nodeDetail.basicInfo.api_status_map.hold"),
        this.$t("nodeDetail.basicInfo.api_status_map.bin"),
        this.$t("nodeDetail.basicInfo.api_status_map.unsure"),
        this.$t("nodeDetail.basicInfo.api_status_map.develop"),
        this.$t("nodeDetail.basicInfo.api_status_map.test"),
        this.$t("nodeDetail.basicInfo.api_status_map.cor"),
        this.$t("nodeDetail.basicInfo.api_status_map.ana"),
        this.$t("nodeDetail.basicInfo.api_status_map.design"),
        this.$t("nodeDetail.basicInfo.api_status_map.complete"),
      ],
      circle_options: {
        legend: {
          right: "right",
          top: "10%",
          icon: "circle",
          textStyle: {
            fontSize: 10,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            startAngle: "270",
            itemStyle: {
              borderWidth: "5",
              borderColor: "#fff",
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      api_statistics: {},
      table_data: [],
      filed_map: {
        api_published_count: this.$t(
          "nodeDetail.basicInfo.api_status_map.published"
        ),
        api_protect_count: this.$t("nodeDetail.basicInfo.api_status_map.hold"),
        api_trash_count: this.$t("nodeDetail.basicInfo.api_status_map.bin"),
        api_wait_confirm_count: this.$t(
          "nodeDetail.basicInfo.api_status_map.unsure"
        ),
        api_dev_count: this.$t("nodeDetail.basicInfo.api_status_map.develop"),
        api_test_count: this.$t("nodeDetail.basicInfo.api_status_map.test"),
        api_docking_count: this.$t("nodeDetail.basicInfo.api_status_map.cor"),
        api_error_count: this.$t("nodeDetail.basicInfo.api_status_map.ana"),
        api_designing_count: this.$t(
          "nodeDetail.basicInfo.api_status_map.design"
        ),
        api_finish_count: this.$t(
          "nodeDetail.basicInfo.api_status_map.complete"
        ),
        has_case_count: this.$t("test.report.info1"),
        no_case_count: this.$t("test.report.info2"),
        has_scene_count: this.$t("test.report.info3"),
        no_scene_count: this.$t("test.report.info4"),
        passed_count: this.$t("test.report.info5"),
        failed_count: this.$t("test.report.info6"),
        no_test_count: this.$t("test.report.info7"),
      },
    };
  },
  computed: {},
  mounted() {
    const params = {
      projectId: this.get_pid(),
      testPlanId: this.cur_test_id,
    };
    apiTestStatistics(params).then((res) => {
      if (!res.api_statistics) {
        this.$emit("hide_test_report");
        return;
      }
      this.api_statistics = res.api_statistics;
      this.initCharts();
    });
    getapiDocList(params).then((res) => {
      this.table_data = res;
    });
    let element = this.$refs["test_report_wrap"];
    this.observer = new ResizeObserver(() => {
      this.resize_pie(); // 触发resize后执行
    });
    this.observer.observe(element);
  },
  methods: {
    resize_pie() {
      this.pie1 ? this.pie1.resize() : "";
      this.pie2 ? this.pie2.resize() : "";
      this.pie3 ? this.pie3.resize() : "";
    },
    initCharts() {
      this.initpie1(this.circle_options);
      this.initpie2(this.circle_options);
      this.initpie3(this.circle_options);
    },
    initpie1(options) {
      const own_options = Object.assign({}, options);
      const data = [];
      const map = ["has_case_count", "no_case_count"];
      let allnumber = 0;
      let hasnumber = 0;
      for (let key in this.api_statistics.api_test_case_coverage) {
        if (map.indexOf(key) !== -1) {
          if (key === "has_case_count") {
            hasnumber = this.api_statistics.api_test_case_coverage[key];
          }
          allnumber += this.api_statistics.api_test_case_coverage[key];
          const obj = {
            name: this.filed_map[key],
            value: this.api_statistics.api_test_case_coverage[key],
          };
          data.push(obj);
        }
      }
      own_options.series[0].data = data;
      own_options.title = {
        text: ((hasnumber / allnumber) * 100).toFixed(0) + "%",
        right: "60%",
        top: "45%",
        textStyle: {
          fontSize: 30,
        },
      };
      own_options.legend.formatter = function (label) {
        const obj = data.filter((item) => {
          return item.name === label;
        });
        return `${label}: ${obj[0].value}`;
      };
      const chart = document.getElementById("pie1");
      this.pie1 = this.$echarts.init(chart);
      this.pie1.setOption(own_options);
    },
    initpie2(options) {
      const own_options = Object.assign({}, options);
      const data = [];
      const map = ["has_scene_count", "no_scene_count"];
      let allnumber = 0;
      let hasnumber = 0;
      for (let key in this.api_statistics.api_test_case_coverage) {
        if (map.indexOf(key) !== -1) {
          if (key === "has_scene_count") {
            hasnumber = this.api_statistics.api_test_case_coverage[key];
          }
          allnumber += this.api_statistics.api_test_case_coverage[key];
          const obj = {
            name: this.filed_map[key],
            value: this.api_statistics.api_test_case_coverage[key],
          };
          data.push(obj);
        }
      }
      own_options.series[0].data = data;
      own_options.title = {
        text: ((hasnumber / allnumber) * 100).toFixed(0) + "%",
        right: "60%",
        top: "45%",
        textStyle: {
          fontSize: 30,
        },
      };
      own_options.legend.formatter = function (label) {
        const obj = data.filter((item) => {
          return item.name === label;
        });
        return `${label}: ${obj[0].value}`;
      };
      const chart = document.getElementById("pie2");
      this.pie2 = this.$echarts.init(chart);
      this.pie2.setOption(own_options);
    },
    initpie3(options) {
      const own_options = Object.assign({}, options);
      const data = [];
      for (let key in this.api_statistics.api_status_data) {
        if (this.api_statistics.api_status_data[key]) {
          const obj = {
            name: this.filed_map[key],
            value: this.api_statistics.api_status_data[key],
          };
          data.push(obj);
        }
      }
      own_options.series[0].data = data;
      own_options.legend.formatter = function (label) {
        const obj = data.filter((item) => {
          return item.name === label;
        });
        return `${label}: ${obj[0].value}`;
      };
      const chart = document.getElementById("pie3");
      const new_options = Object.assign({}, own_options);
      new_options.series[0].radius = ["0%", "50%"];
      new_options.title = {};
      this.pie3 = this.$echarts.init(chart);
      this.pie3.setOption(new_options);
    },
  },
};
</script>

<style lang="scss" scoped>
.test_report_wrap {
  padding: 10px 40px;
  & > p {
    text-align: right;
  }
  & > .test_report_main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > h3 {
      width: 100%;
      text-align: left;
    }
    & > .text_report_left {
      width: 65%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > div {
        width: 48%;
        margin-bottom: 26px;
        border-radius: 5px;
        border: 1px solid rgba(235, 238, 245, 1);
        padding: 28px;
        &:nth-of-type(1),
        &:nth-of-type(2) {
          & > h3 {
            margin: 0;
            text-align: left;
            font-size: 16px;
          }
          & > p {
            font-size: 36px;
            margin: 0;
          }
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          & > h3 {
            font-size: 16px;
            text-align: left;
            & > a {
              font-size: 12px;
              float: right;
            }
          }
          & > div {
            width: 100%;
            height: 250px;
          }
        }
      }
    }
    & > .text_report_right {
      width: 32%;
      & > div {
        padding: 28px;
        border-radius: 5px;
        border: 1px solid rgba(235, 238, 245, 1);
        & > h3 {
          margin: 0;
          text-align: left;
          font-size: 16px;
        }
        & > div {
          height: 459px;
        }
      }
    }
  }
  & > .test_report_table_wrap {
    & > h3 {
      width: 100%;
      text-align: left;
    }
    & > .test_report_table {
      width: 100%;
      justify-content: space-around;
    }
  }
}
.api_status {
  padding: 0 26px;
  display: inline-block;
  border-radius: 4px;
  background-color: rgba(182, 219, 242, 1);
}
</style>
