<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('loading.loading4')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    class="bug_overview_warp"
  >
    <div class="add_button_class" v-if="is_edit">
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="add_pie_cus_charts_list"
      >
        {{ $t("chart.addDiagramBtn") }}</el-button
      >
    </div>
    <div ref="pie_wrap" class="pie_wrap">
      <div v-for="(item, index) in pie_default_list" :key="'defalut' + index">
        <el-select
          v-model="item.fieldId"
          size="mini"
          filterable
          :placeholder="$t('placeholder.dropdown')"
          @change="change_default_pie($event, index)"
        >
          <el-option
            v-for="option in select_options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
        <div
          :id="'pie_test' + item.fieldId + index"
          :ref="'pie_' + item.fieldId + index"
          class="pie_box"
        ></div>
      </div>
      <div
        v-for="(item, index) in pie_cus_list"
        v-show="item"
        :key="'cus' + index"
      >
        <el-select
          v-if="item"
          v-model="item.fieldId"
          size="mini"
          filterable
          :placeholder="$t('placeholder.dropdown')"
          @change="change_cus_pie($event, index)"
        >
          <el-option
            v-for="option in select_options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
        <div
          class="remove_button"
          @click="remove_pie_cus_charts_list(index)"
          v-if="is_edit"
        >
          <i class="el-icon-circle-close" style="font-size: 30px"></i>
        </div>
        <div
          :id="'pie_test' + index"
          :ref="'pie_' + index"
          class="pie_box"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  testOverviewFields,
  testReportFields,
  testOverview,
  testOverviewOptions,
  savetestOverviewOptions,
  testCaseStatus,
} from "@/network/testPlan/index.js";
export default {
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      select_options: [],
      pie_default_list: [],
      charts_options: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          right: "0",
          icon: "circle",
          top: "20%",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            minAngle: 5,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}: {c} ({d}%)',
              textStyle: {
                fontSize: 12,
                fontWeight: 'normal',
              },
            },
            labelLine: {
              show: true,
              length: 10, // 扇形边上的线条长度
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
      },
      pie_cus_list: [],
      pie_default_charts_list: [],
      pie_cus_charts_list: [],
      testCaseStatusList: [],
    };
  },
  computed: {
    testCaseStatusObj() {
      const obj = {};
      this.testCaseStatusList.forEach((item) => {
        obj[item.status] = item.statusName;
      });
      return obj;
    },
  },
  mounted() {
    this.testReportFields();
    // this.init_default_options();
    let element = this.$refs["pie_wrap"];
    this.observer = new ResizeObserver(() => {
      this.resize_pie(); // 触发resize后执行
    });
    this.observer.observe(element);
    this.getTestCaseStatus();
  },
  methods: {
    getTestCaseStatus() {
      testCaseStatus(this.get_pid()).then((res) => {
        this.testCaseStatusList = res;
      });
    },
    EXECUTED_STATUS__MAP(data) {
      return this.testCaseStatusObj[data];
    },
    // 删除自定义图表
    remove_pie_cus_charts_list(index) {
      this.loading = true;
      this.pie_cus_list[index] = null;
      this.pie_cus_charts_list[index] = null;
      this.pie_cus_list.push();
      this.savetestOverviewOptions(false);
    },
    // 添加自定义图表
    add_pie_cus_charts_list() {
      this.loading = true;
      this.pie_cus_list.push({
        fieldName: "",
        fieldId: "",
      });
      this.$nextTick(() => {
        this.savetestOverviewOptions(false);
        this.initpie([], this.pie_cus_charts_list.length, null, false);
      });
    },
    async get_pie_options(fieldId) {
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        testOverviewType: "TEST_CASE",
        data: [fieldId],
      };
      return testOverview(params);
    },
    user_name(actualValue) {
      return this.matchUserNickname(actualValue, "null");
    },
    // 默认下拉框change事件
    async change_default_pie(value, index) {
      const match_options = this.select_options.filter((item) => {
        return item.value === value;
      });
      this.pie_default_list[index].fieldName = match_options.name;
      this.savetestOverviewOptions(false);
      const res = await this.get_pie_options(value);
      const arr = [];
      res[0].testOverviewEntryList.forEach((item) => {
        const obj = {
          name:
            match_options[0].fieldType === "MULTI_USER_SELECT" ||
            match_options[0].fieldType === "SINGLE_USER_SELECT"
              ? this.user_name(item.actualValue)
              : res[0].fieldId === "execute_status"
              ? this.EXECUTED_STATUS__MAP(item.actualValue)
              : item.actualValue,
          value: item.count,
        };
        arr.push(obj);
      });
      this.initpie(arr, value, this.pie_default_charts_list[index], true);
    },
    async change_cus_pie(value, index) {
      const match_options = this.select_options.filter((item) => {
        return item.value === value;
      });
      this.pie_cus_list[index].fieldName = match_options.name;
      this.savetestOverviewOptions(false);
      const res = await this.get_pie_options(value);
      const arr = [];
      res[0].testOverviewEntryList.forEach((item) => {
        const obj = {
          name:
            match_options[0].fieldType === "MULTI_USER_SELECT" ||
            match_options[0].fieldType === "SINGLE_USER_SELECT"
              ? this.user_name(item.actualValue)
              : res[0].fieldId === "execute_status"
              ? this.EXECUTED_STATUS__MAP(item.actualValue)
              : item.actualValue,
          value: item.count,
        };
        arr.push(obj);
      });
      this.initpie(arr, value, this.pie_cus_charts_list[index], false);
    },
    resize_pie() {
      const opts = {
        width: 'auto', // 自动取实例容器的宽度
        height: 'auto', // 自动取实例容器的高度
        silent: false, // 是否禁止抛出事件
        animation: {
          duration: 300, // 过渡动画的时长，单位为毫秒
          easing: 'cubicOut', // 缓动函数
        },
      };

      this.pie_default_charts_list.forEach((item) => {
        if (item) {
          item.resize(opts);
        }
      });

      this.pie_cus_charts_list.forEach((item) => {
        if (item) {
          item.resize(opts);
        }
      });
    },
    initpie(data, fieldId, charts, isdefault, index) {
      const option = Object.assign({}, this.charts_options);
      (this.charts_options.legend.formatter = function (label) {
        const obj = data.filter((item) => {
          return item.name === label;
        });
        return `${label}: ${obj[0].value}`;
      }),
        (option.series[0].data = data);
      if (charts) {
        charts.setOption(option);
      } else {
        const chart = document.getElementById(
          "pie_test" + fieldId + (typeof index === "number" ? index : "")
        );
        const pie = this.$echarts.init(chart);
        isdefault
          ? this.pie_default_charts_list.push(pie)
          : this.pie_cus_charts_list.push(pie);
        pie.setOption(option);
      }
    },
    testReportFields() {
      this.select_options = [];
      testOverviewFields({ projectId: this.get_pid(), type: "TEST_CASE" }).then(
        (res) => {
          res.forEach((item) => {
            const obj = Object.assign({}, item, {
              label: item.name,
              value: item.fieldId,
            });
            this.select_options.push(obj);
          });
          this.init_default_options();
        }
      );
    },
    savetestOverviewOptions(flag) {
      const all_list = [];
      const default_options = this.select_options.filter((item) => {
        return item.value === "execute_status" || item.value === "executor";
      });
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        testOverviewType: "TEST_CASE",
        data: {
          fieldInfoList: flag
            ? default_options
            : all_list
                .concat(this.pie_default_list, this.pie_cus_list)
                .filter((item) => {
                  return item;
                }),
          type: "TEST_CASE",
        },
      };
      savetestOverviewOptions(params).then((res) => {
        this.loading = false;
        flag ? this.init_default_options() : "";
      });
    },
    init_default_options() {
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        testOverviewType: "TEST_CASE",
      };
      testOverviewOptions(params).then((res) => {
        if (!res.fieldInfoList) {
          this.savetestOverviewOptions(true);
        } else {
          this.pie_default_charts_list = [];
          this.pie_default_list = res.fieldInfoList.slice(0, 2);
          this.pie_cus_list = res.fieldInfoList.slice(
            2,
            res.fieldInfoList.length
          );
          const dafault_promise_all = [];
          this.pie_default_list.forEach(async (item) => {
            const params = {
              projectId: this.get_pid(),
              testPlanId: this.cur_test_id,
              testOverviewType: "TEST_CASE",
              data: [item.fieldId],
            };
            dafault_promise_all.push(testOverview(params));
          });
          Promise.all(dafault_promise_all).then((alldata) => {
            alldata.forEach((res, index) => {
              const match_options = this.select_options.filter((option) => {
                return option.value === this.pie_default_list[index].fieldId;
              });
              const arr = [];
              res[0].testOverviewEntryList.forEach((item) => {
                const obj = {
                  name:
                    match_options[0].fieldType === "MULTI_USER_SELECT" ||
                    match_options[0].fieldType === "SINGLE_USER_SELECT"
                      ? this.user_name(item.actualValue)
                      : res[0].fieldId === "execute_status"
                      ? this.EXECUTED_STATUS__MAP(item.actualValue)
                      : item.actualValue,
                  value: item.count,
                };
                arr.push(obj);
              });
              this.initpie(
                arr,
                this.pie_default_list[index].fieldId,
                null,
                true,
                index
              );
            });
          });
          const promise_all = [];
          this.pie_cus_list.forEach(async (item, index) => {
            const params = {
              projectId: this.get_pid(),
              testPlanId: this.cur_test_id,
              testOverviewType: "TEST_CASE",
              data: [item.fieldId],
            };
            promise_all.push(testOverview(params));
          });
          Promise.all(promise_all).then((alldata) => {
            alldata.forEach((res, index) => {
              const match_options = this.select_options.filter((option) => {
                return option.value === this.pie_cus_list[index].fieldId;
              });
              const arr = [];
              res[0].testOverviewEntryList.forEach((item) => {
                const obj = {
                  name:
                    match_options[0].fieldType === "MULTI_USER_SELECT" ||
                    match_options[0].fieldType === "SINGLE_USER_SELECT"
                      ? this.user_name(item.actualValue)
                      : res[0].fieldId === "execute_status"
                      ? this.EXECUTED_STATUS__MAP(item.actualValue)
                      : item.actualValue,
                  value: item.count,
                };
                arr.push(obj);
              });
              this.initpie(arr, index, null, false);
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bug_overview_warp {
  position: relative;
  .add_button_class {
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 9;
  }
  .pie_wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: left;
    overflow: auto;
    // border: 1px solid #000;
    & > div {
      width: 40%;
      margin: 0 5%;
      position: relative;
      // border: 1px solid #000;
      .remove_button {
        position: absolute;
        right: 100px;
        width: 30px;
        height: 30px;
        top: 0;
        cursor: pointer;
        // border: 1px solid #000;
      }
      & > .pie_box {
        margin: 0 auto;
        width: 100%;
        height: 400px;
        // border: 1px solid #000;
      }
    }
  }
}
</style>
