//测试用例列表相关路由
import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
export function add_test_node(projectId, nodeKey, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/testCases`,
    method: "post",
    data: nodeKey,
  })
}

//获取测试计划下的所有测试节点
export function get_test_nodes(projectId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/testCases`,
    method: "get",
  })
}
// 删除测试用例节点

export function delete_test_node(projectId, testPlanId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/testCases/${nodeKey}`,
    method: "delete",
  })
}

//修改节点执行状态
export function execute_test_node(projectId, testPlanId, nodeKey, execute_data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/testCases/${nodeKey}`,
    method: "put",
    data: execute_data
  })
}
//修改节点执行状态
export function test_coverage(projectId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/testCoverage`,
    method: "get",
  })
}
// 获取节点执行状态
export function get_test_case_status(params) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${params.projectId}/testCases/${params.nodeKey}/records`,
  })
}
//添加测试用例和bug的关系
export function postTestcaseBugRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/testPlan/testcaseBugRelations`,
    method: "post",
    data: params
  })
}
//删除测试用例和BUG的关系
export function deleteTestcaseBugRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/testPlan/testcaseBugRelations`,
    method: "delete",
    params: params
  })
}
//获取项目下可关联bug
export function getBugNodes(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/bugNodes`,
    method: "get",
    params: {
      fuzzyKey,
    },
  })
}
