<template>
  <div ref="test_report_wrap" class="test_report_wrap">
    <div class="test_report_main">
      <div class="text_report_left">
        <div>
          <h3>{{ $t("corax.StaticCodeScanPieChart") }}</h3>
          <div id="pieBug" ref="pieBug"></div>
        </div>
      </div>
      <div class="text_report_right">
        <div>
          <h3>{{ $t("corax.LinesOfCode") }}</h3>
          <p>{{ rowData.line_number }}</p>
        </div>
        <div>
          <h3>{{ $t("corax.NumberOfFiles") }}</h3>
          <p>{{ rowData.file_number }}</p>
        </div>
        <div>
          <h3>{{ $t("corax.ScanningCriteria") }}</h3>
          <p>{{ rowData.standards }}</p>
        </div>
        <div>
          <h3>{{ $t("corax.ScanningTime") }}</h3>
          <p>{{ rowData.scan_time }}</p>
        </div>
        <div class="link">
          <h3>{{ $t("corax.PlatformLinks") }}</h3>
          <a :href="rowData.link" target="_blank">{{ rowData.link }}</a>
        </div>
      </div>
    </div>
    <div class="test_report_table_wrap">
      <h3>{{ $t("corax.DefectDetails") }}</h3>
      <div class="test_report_table">
        <div>
          <el-table
            :data="rowData.bug_list"
            style="width: 100%"
            :header-cell-style="{
              color: '#383838',
              'font-weight': '700',
            }"
            :cell-style="{
              color: '#636363',
              'font-weight': '700',
            }"
          >
            <el-table-column
              prop="bug_id"
              :label="$t('corax.GlobalNumber')"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="bug_code"
              show-overflow-tooltip
              :label="$t('corax.BugNumber')"
            >
            </el-table-column>
            <el-table-column
              prop="bug_name"
              show-overflow-tooltip
              :label="$t('corax.DefectName')"
            >
            </el-table-column>
            <el-table-column
              prop="bug_description"
              show-overflow-tooltip
              :label="$t('corax.DefectDescription')"
            >
            </el-table-column>
            <el-table-column
              prop="bug_position"
              show-overflow-tooltip
              :label="$t('corax.DefectLocation')"
            >
            </el-table-column>
            <el-table-column
              prop="severity"
              :label="$t('corax.Severity')"
            >
              <template slot="header">
                <div
                  class="sortable-column-header"
                  @click="severity_sort_toggle"
                >
                  {{ $t("corax.Severity") }}
                  <div class="caret-wrapper">
                    <i
                      :class="{
                        'el-icon-caret-top': true,
                        'el-icon-caret-colored':
                          sortby == 'severity' && desc == false,
                      }"
                    ></i>
                    <i
                      :class="{
                        'el-icon-caret-bottom': true,
                        'el-icon-caret-colored':
                          sortby == 'severity' && desc == true,
                      }"
                    ></i>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div
                    v-if="scope.row.severity === 'high'"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      background: red;
                      margin-right: 5px;
                    "
                  ></div>
                  <div
                    v-if="scope.row.severity === 'medium'"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      background: orange;
                      margin-right: 5px;
                    "
                  ></div>
                  <div
                    v-if="scope.row.severity === 'low'"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      background: yellow;
                      margin-right: 5px;
                    "
                  ></div>
                  <div
                    v-if="scope.row.severity === 'info'"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      background: blue;
                      margin-right: 5px;
                    "
                  ></div>
                  <div>{{ scope.row.severity }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      circle_options: {
        legend: {
          right: "right",
          top: "10%",
          icon: "circle",
          textStyle: {
            fontSize: 10,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            startAngle: "270",
            itemStyle: {
              borderWidth: "5",
              borderColor: "#fff",
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      sortby: "",
      desc: false,
    };
  },
  mounted() {
    // this.rowData.bug_list = [
    //     {
    //         "bug_id": "0012",
    //         "bug_code": "FY-STD022-JS",
    //         "bug_name": "Loose cross-domain policy",
    //         "bug_description": "The software uses a cross-domain policy file that includes domains that should not be trusted.",
    //         "bug_position": "6@1.py",
    //         "severity": "medium"
    //     },
    //     {
    //         "bug_id": "0013",
    //         "bug_code": "FY-STD022-JS",
    //         "bug_name": "Loose cross-domain policy",
    //         "bug_description": "The software uses a cross-domain policy file that includes domains that should not be trusted.",
    //         "bug_position": "9@1.py",
    //         "severity": "medium"
    //     },
    //     {
    //         "bug_id": "0014",
    //         "bug_code": "FY-STD023-JS",
    //         "bug_name": "Insecure cookie policy",
    //         "bug_description": "The software sets cookies without using the Secure attribute.",
    //         "bug_position": "12@2.js",
    //         "severity": "high"
    //     },
    //     {
    //         "bug_id": "0015",
    //         "bug_code": "FY-STD024-JS",
    //         "bug_name": "Inadequate input validation",
    //         "bug_description": "The software does not properly validate input before using it in processing.",
    //         "bug_position": "18@3.py",
    //         "severity": "info"
    //     },
    //     {
    //         "bug_id": "0016",
    //         "bug_code": "FY-STD025-JS",
    //         "bug_name": "Sensitive data exposure",
    //         "bug_description": "The software exposes sensitive information in error messages.",
    //         "bug_position": "22@4.js",
    //         "severity": "low"
    //     },
    //     {
    //         "bug_id": "0017",
    //         "bug_code": "FY-STD026-JS",
    //         "bug_name": "Insecure direct object references",
    //         "bug_description": "The software references a specific object without checking if the user is authorized to access it.",
    //         "bug_position": "32@5.py",
    //         "severity": "high"
    //     },
    //     {
    //         "bug_id": "0018",
    //         "bug_code": "FY-STD027-JS",
    //         "bug_name": "Insecure encryption",
    //         "bug_description": "The software uses weak encryption algorithms.",
    //         "bug_position": "46@6.js",
    //         "severity": "low"
    //     }
    // ];
    this.initpieBug(this.circle_options, this.rowData);
    let element = this.$refs["test_report_wrap"];
    this.observer = new ResizeObserver(() => {
      this.resize_pie(); // 触发resize后执行
    });
    this.observer.observe(element);
  },
  watch: {
    rowData(newVal) {
      this.initpieBug(this.circle_options, newVal);
    },
    desc() {
      let upIcon = document.querySelector(".el-icon-caret-top");
      let downIcon = document.querySelector(".el-icon-caret-bottom");

      if (this.desc) {
        upIcon.classList.remove("el-icon-caret-colored");
        downIcon.classList.add("el-icon-caret-colored");
      } else {
        downIcon.classList.remove("el-icon-caret-colored");
        upIcon.classList.add("el-icon-caret-colored");
      }
    },
    sortby() {
      let upIcon = document.querySelector(".el-icon-caret-top");
      let downIcon = document.querySelector(".el-icon-caret-bottom");

      if (this.desc) {
        upIcon.classList.remove("el-icon-caret-colored");
        downIcon.classList.add("el-icon-caret-colored");
      } else {
        downIcon.classList.remove("el-icon-caret-colored");
        upIcon.classList.add("el-icon-caret-colored");
      }
    },
  },
  methods: {
    severity_sort_toggle() {
      if (this.sortby === "severity") {
        this.desc = !this.desc;
      } else {
        this.sortby = "severity";
        this.desc = false;
      }
      this.sortData();
    },
    sortData() {
      // Define mapping
      const severityLevels = {
        high: 4,
        medium: 3,
        low: 2,
        info: 1,
      };
      const a = this.rowData.bug_list.sort((a, b) => {
        const comparison =
          severityLevels[a.severity] - severityLevels[b.severity];
        return this.desc ? -comparison : comparison;
      });
      this.rowData.bug_list = a;
    },
    resize_pie() {
      this.pieBug ? this.pieBug.resize() : "";
    },
    initpieBug(options, rowData) {
      // console.log(rowData);
      const own_options = Object.assign({}, options);
      const data = [];
      for (let key in rowData.bug_number) {
        if (rowData.bug_number[key]) {
          const obj = {
            name: key, // use the key as the label (i.e., "high", "medium", "low", "info")
            value: rowData.bug_number[key],
          };
          data.push(obj);
        }
      }
      own_options.series[0].data = data;
      own_options.legend.formatter = function (label) {
        const obj = data.filter((item) => {
          return item.name === label;
        });
        return `${label}: ${obj[0].value}`;
      };
      const chart = document.getElementById("pieBug");
      const new_options = Object.assign({}, own_options);
      new_options.series[0].radius = ["0%", "50%"];
      new_options.title = {};
      this.pieBug = this.$echarts.init(chart);
      this.pieBug.setOption(new_options);
    },
  },
};
</script>
<style lang="scss" scoped>
.test_report_wrap {
  padding: 10px 40px;
  & > p {
    text-align: right;
  }
  & > .test_report_main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > h3 {
      width: 100%;
      text-align: left;
    }
    & > .text_report_right {
      width: 65%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > div {
        width: 48%;
        margin-bottom: 26px;
        border-radius: 5px;
        border: 1px solid rgba(235, 238, 245, 1);
        padding: 28px;
        &:nth-of-type(1),
        &:nth-of-type(2) {
          & > h3 {
            margin: 0;
            text-align: left;
            font-size: 16px;
          }
          & > p {
            font-size: 36px;
            margin: 0;
          }
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          & > h3 {
            margin-top: 0;
            font-size: 16px;
            text-align: left;
          }
          & > div {
            width: 100%;
            height: 250px;
          }
          & > p {
            font-size: 24px;
            margin: 0;
          }
        }
      }
      & > .link {
        width: 100%;
        margin-bottom: 0;
        & > h3 {
          margin-top: 0;
          text-align: left;
          font-size: 16px;
        }
        & > a {
          font-size: 24px;
          margin: 0;
        }
      }
    }
    & > .text_report_left {
      width: 32%;
      & > div {
        padding: 28px;
        border-radius: 5px;
        border: 1px solid rgba(235, 238, 245, 1);
        height: 100%;

        & > h3 {
          margin: 0;
          text-align: left;
          font-size: 16px;
        }
        & > div {
          height: 459px;
        }
      }
    }
  }
  & > .test_report_table_wrap {
    & > h3 {
      width: 100%;
      text-align: left;
    }
    & > .test_report_table {
      width: 100%;
      justify-content: space-around;
    }
  }
}
.sortable-column-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}
.el-icon-caret-colored {
  color: #068bff;
}
</style>
